<template>
    <div id="box">
        <Header :headerIndex="0"></Header>
        <div class="container">
            <div class="index">
                <swiper :options="swiperOption" ref="mySwiper" class="m1440">
                    <swiper-slide v-for="item in banner"><img :src="item.image_text" alt="" class="banner m1440" @click="gourl(item)"></swiper-slide>
                </swiper>
                <div class="" v-if="user">
                    <div class="m1340">
                        <div class="info_box flex-box mtb20" >
                            <div class="flex-box info">

                                <img :src="user.avatar ? user.avatar : require('../assets/static/icon/avatar.png')"
                                    class="avatar" alt="" />
                                <div class="pl40">
                                    <div class=" flex-box fs24" :class="user.is_special==1?'name':''">{{ user.idnum }}</div>
                                    <div class="id fs18 pt20">ID: {{ user.id }}</div>
                                </div>
                            </div>
                            <div class="inventory flex-grow-1">
                                <div class="fs14 col5 pb15">YOUR INVENTORY</div>
                                <div class="flex-box flex-between">
                                    <div>
                                        <span class="fs18">{{ user.coins | numberToCurrency }}</span>
                                        M-Coins
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.rp | numberToCurrency }}</span>
                                        RP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.pp | numberToCurrency }}</span>
                                        PP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.ap | numberToCurrency }}</span>
                                        AP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.ep | numberToCurrency }}</span>
                                        EP
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link class="btn more" to="/market" >MORE+</router-link> -->
                    </div>
                </div>
                <div class="part2">
                    <div class="m1440 index_blog">
                        <div class="title tc">&nbsp;SMA&nbsp;成就千万家庭&nbsp;</div>
                        <div class="introduction tc" style="text-align: left;line-height: 36px;font-size:16px;width:680px;padding-top:20px;">

                          亲爱的伙伴<br/>
                          SMA 五年规划 百亿美金<br/>
                          11个国家 百城之战<br/>
                          经过8个月的市场健康稳定的发展<br/>
                          我们经受住市场最激烈的考验<br/>
                          在金融危机经济下行的情况下 我们完成的3次拆分的目标<br/><br/>

                          亲爱的伙伴们惊喜的同时不要忘记<br/>
                          做好三出三进 百万代币财富自由<br/>
                          我们还需努力 百尺竿头更进一步<br/>
                          100位GEC 领袖合伙人<br/>
                          已经结缘了8位<br/>
                          2024 10座城 10位领袖<br/>
                          1000万美金业绩<br/>
                          SMA 全球资产管理公司<br/>
                          全球贸易公司<br/>
                          从北美到欧洲到亚洲<br/>
                          从IT 到生物科技到有机食品 从意大利酒庄酒店餐饮<br/>
                          从新加坡移民马来西亚燕窝榴莲供应链<br/>
                          从泰国旅游再到大中华地区<br/>
                          中国大陆市场<br/>
                          国际投行对品牌饮料的布局<br/>
                          数字化仓储配送的布局<br/>
                          新能源车的布局<br/>
                          香港资产配置金融业务的布局台湾食品美业的布局！<br/>
                          SMA已经是一家涵盖全球业务的公司<br/><br/>

                          因为一个人爱上一座城<br/>
                          一亿美金一座城<br/>
                          5年后的今天<br/>
                          现金流是您的 仓储是您的<br/>
                          新能源车队是您的 经销权是您<br/>
                          全球优质供应链是您的<br/><br/>

                          亲爱的伙伴<br/>
                          用您的可以支配的资金<br/>
                          智慧的思维 明智的抉择<br/>
                          一起创造百城奇迹！<br/><br/>

                          SMA 全球执行委员会<br/>
                          08.10.2024
                        </div>
                    </div>
                </div>
                <div class="part2">
                    <div class="m1440 index_blog">
                        <div class="title tc">FAQ&BLOG</div>
                        <div class="introduction tc">Description of common problems. Description of common problems. </div>
                        <div class="flex-box flex-between faq_list tc">
                            <template v-for="(item, index) in articlelist">
                                <a v-if="!item.url" href="javascript:;" @click="godetail(item)" class="faq">
                                    <img :src="item.image_text" class="pics" alt="">
                                    <div class="fs24">{{ item.name }}</div>
                                </a>
                                <a v-else :href="item.url" class="faq">
                                    <img :src="item.image_text" class="pics" alt="">
                                    <div class="fs24">{{ item.name }}</div>
                                </a>
                            </template>
                            <!-- <a href="javascript:;" class="faq">
                                <img src="../assets/static/icon/pic2.png" class="pics" alt="">
                                <div class="fs24">FDISCOVERY</div>
                            </a>
                            <a href="javascript:;" class="faq">
                                <img src="../assets/static/icon/pic3.png" class="pics" alt="">
                                <div class="fs24">BLOG</div>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import cookie from "vue-cookies";
import { numberToCurrency } from "../assets/filters";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
import "swiper/swiper-bundle.css";

export default {
    filters: {
        numberToCurrency
    },
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            price: '',
            articlelist: '',
            user: '',
            configs: '',
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 显示分页
            },
            banner:''
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    mounted() {
        // current swiper instance
        // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
        console.log("this is current swiper instance object", this.swiper);
        // this.swiper.slideTo(3, 1000, false);
    },
    created() {
        this.article()
        this.getuser()
        this.config()

        this.getbanner()
    },
    methods: {
        gourl(item){
            if(item.url){
                window.location.href = item.url;

            }
        },
        getbanner(){
            this.$api.getbanner({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.banner = res.data
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })

                }
            })
        },
        config() {
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.configs = res.data
                } else {
                    // that.$message.error(res.msg);

                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        article() {
            this.$api.apiArticle({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articlelist = res.data
                    console.log(this.articlelist)
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        getuser() {
            if (cookie.get('sma_token')) {
                this.$api.apiUserInfo({}).then(ret => {
                    if (ret.code == 1) {

                        this.user = ret.data.userinfo;

                    }
                })
            }


        },
        godetail(item) {
            // this.$router.push({ path: '/articledetail', query: { id: item.id } })
        }
    },
};
</script>
